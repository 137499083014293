.scroll {
    padding: 10px 0;
}

.scroll::-webkit-scrollbar {
    width: 9px;
}

.scroll::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(49, 50, 57, 1);
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(230, 230, 255, 0.2);
}
