:root {
  --stepper-circle: 30px;
}

.stepper {
  height: calc(var(--stepper-circle));
  transition: all 0.3s;
}

.stepper_circle {
  width: var(--stepper-circle);
  height: var(--stepper-circle);
  transition: all 0.3s;
}

.stepper__title {
  position: absolute;
  top: calc(var(--stepper-circle) + 5px);
  padding: 0 15px;
  font-weight: bold;
  transition: all 0.3s;
  text-align: center;
}

.stepper__item:not(:last-child):after {
  --spacing: 10px;
  --width: 5px;
  content: "";
  background-color: #a8b2bd;
  display: block;
  height: var(--width);
  border-radius: var(--width);
  transition: all 0.3s;
  width: calc(100% - var(--spacing) * 2 - var(--stepper-circle));
  left: calc(50% + var(--stepper-circle) / 2 + var(--spacing));
  top: calc(50% - var(--width));
  position: absolute;
}

.stepper__active.stepper__item:not(:last-of-type):after {
  background-color: #60d11b;
}

@media screen and (max-width: 800px) {
  :root {
    --stepper-circle: 25px;
  }

  .stepper_circle {
    font-size: 10px;
  }

  .stepper__title {
    font-size: 12px;
  }
}
