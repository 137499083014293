.switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 20px;
}

.disabled .slider {
    background-color: #eee;
}

@media screen and (max-width: 1300px) {
    .switch {
        width: 27px;
        height: 15px;
    }
}

@media screen and (min-width: 1300px) {
    .switch {
        width: 37px;
        height: 20px;
    }
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 25px;
    transition: 0.3s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

@media screen and (max-width: 1300px) {
    .slider:before {
        height: 11px;
        width: 11px;
        bottom: 2px;
        left: 2px;
    }
}

@media screen and (min-width: 1300px) {
    .slider:before {
        height: 14px;
        width: 14px;
    }
}

.switch > input:checked + .slider:before {
    transform: translateX(16px);
}

@media screen and (max-width: 1300px) {
    .switch > input:checked + .slider:before {
        transform: translateX(12px);
    }
}

.switch > input:checked + .slider {
    background-color: #6300f1;
}
