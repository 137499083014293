@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: local("Roboto"), url("./fonts/Roboto-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: local("Roboto"), url("./fonts/Roboto-Medium.ttf") format("truetype");
  }

  @font-face {
    font-family: "Roboto";
    font-weight: bold;
    src: local("Roboto"), url("./fonts/Roboto-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Roboto";
    font-weight: 900;
    src: local("Roboto"), url("./fonts/Roboto-Black.ttf") format("truetype");
  }

  * {
    font-family: "Roboto";
  }
}

#tiflux--chat {
  visibility: hidden;
}
